import google from "./google";

const getAncestors = (el: HTMLElement) => {
  const ancestors = [];
  let currentEl: HTMLElement | ParentNode | null = el;
  while (currentEl && currentEl instanceof HTMLElement) {
    ancestors.unshift(currentEl);
    currentEl = currentEl.parentNode;
  }
  return ancestors;
};

const hasPhoneAnchorAncestor = (el: HTMLElement) => {
  const ancestors = getAncestors(el);
  const anchor = ancestors.find((el) => {
    return el.tagName === "A";
  });
  if (anchor && anchor.getAttribute("href")?.startsWith("tel:")) {
    return true;
  }
  return false;
};

function setup(track: (event: string, data: any) => void) {
  // Track click events
  document.addEventListener("click", function (e) {
    if (!(e.target && e.target instanceof HTMLElement)) {
      return;
    }

    // If the target has a data-track-click attribute, track it with RudderStack
    const target = e.target as HTMLElement;
    const trackValue = target.dataset.trackClick;
    if (trackValue) {
      track("Click", { elementDesc: trackValue });
      if (process.env.NODE_ENV === "development") {
        console.log("Click", trackValue);
      }
    }

    // If the target is a link with a tel: protocol, track it with a Google Tag
    // Manager custom Event
    if (hasPhoneAnchorAncestor(target)) {
      google.dataLayerPush({ event: "PhoneNumberClicked" });
    }
  });
}

export default setup;
