declare var dataLayer: DataLayer;
interface DataLayer {
  push(params: DataLayerParams): void;
}

interface DataLayerParams {
  event?: string;
  email?: string;
}

// Trigger a Google Tag Manager "custom event" trigger
// https://support.google.com/tagmanager/answer/7679219?hl=en
function dataLayerPush(params: DataLayerParams) {
  try {
    dataLayer.push(params);
  } catch (e) {
    if (process.env.NODE_ENV === "development") {
      console.error("dataLayerPush error", e);
    }
  }
}

export default { dataLayerPush: dataLayerPush };
