// I generally do not like hard-coding keys and configuration values into
// scripts, but these values are exposed to the web anyway. If we want more
// configurability in the future we can refactor.
const RUDDERSTACK_DATA_PLANE_URL =
  "https://mightyaarrqct.dataplane.rudderstack.com";
const RUDDERSTACK_WRITE_KEY_PROD = "2AIZliT5CitnWQ3xcdxWxijUJEJ";
const RUDDERSTACK_WRITE_KEY_DEV = "2CMEWXWbtmqSeEx0vRwg0ozmXjd";

function rudderstack() {
  const dataPlaneUrl = RUDDERSTACK_DATA_PLANE_URL;
  const writeKey =
    process.env.NODE_ENV === "production"
      ? RUDDERSTACK_WRITE_KEY_PROD
      : RUDDERSTACK_WRITE_KEY_DEV;

  return {
    writeKey: writeKey,
    dataPlaneUrl: dataPlaneUrl,
  };
}

export default { rudderstack: rudderstack() };
