import * as rudderanalytics from "rudder-sdk-js";

declare global {
  interface Window {
    __rudderanalytics__: object;
  }
}

function setup(writeKey: string, dataPlaneUrl: string) {
  if (writeKey && dataPlaneUrl) {
    rudderanalytics.load(writeKey, dataPlaneUrl);
  }

  window.__rudderanalytics__ = rudderanalytics;

  return rudderanalytics;
}

export default setup;
