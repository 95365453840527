import config from "./config";
import rudderSetup from "./rudderstack";
import trackingSetup from "./tracking";
import { scrollToJobApp } from "./greenhouse";

const rudderanalytics = rudderSetup(
  config.rudderstack.writeKey,
  config.rudderstack.dataPlaneUrl
);
rudderanalytics.page();

trackingSetup(rudderanalytics.track);

scrollToJobApp();
