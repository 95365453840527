import { ready } from "./ready";

// I don't love this code as it's specific to the structure of the
// www.mighty.com site (e.g. the .nav bar) In the future we can try to abstract
// it.
export function scrollToJobApp() {
  ready(() => {
    const param = "gh_jid";
    const id = "grnhse_app";
    const element = document.getElementById(id);
    const hasJid = new URL(document.location.toString()).searchParams.has(
      param
    );
    const navBar = document.getElementsByClassName("nav")[0] as HTMLElement;

    // Return unless we are on a page with the job board and the correct url
    // params. Also, see comment above about .nav bar.
    if (!(element && hasJid && navBar)) {
      return;
    }

    const yOffset = -navBar.offsetHeight;
    const elementTop = element.getBoundingClientRect().top;

    const y = elementTop + window.pageYOffset + yOffset;

    console.log(window.pageYOffset, window.innerHeight, elementTop, yOffset, y);

    if (window.pageYOffset + window.innerHeight < elementTop) {
      setTimeout(() => {
        window.scrollTo({ top: y, behavior: "smooth" });
      }, 200);
    }
  });
}
